import CommentForm from "./CommentForm";
import Result from "./Result";
import ResultForm from "./ResultForm";
import TrainingPlanUnitStandard from "./TrainingPlanUnitStandard";
import { ProgrammeUnitStandardModel } from '../Models/ProgrammeUnitStandardModel';
import { UnitStandardModel } from '../Models/UnitStandardModel';
import { ResultModel } from "../Models/ResultModel";
import TrainingPlanUnitStandardModel from "../Models/TrainingPlanUnitStandardModel";
import { TrainingPlanCommentModel } from '../Models/TrainingPlanCommentModel';
import GlobalStateModel from '../Models/GlobalStateModel';
import ComponentHelperBase, { IUSCheckedEventArg } from '../ComponentHelpers/ComponentHelperBase';
import TokenModel from "../Models/TokenModel";
import { ItpuCreate, ItpuUpdate } from "../Common/TrainingPlanUnitStandardManager";
import TrainingPlanUnitStandardManager from "../Common/TrainingPlanUnitStandardManager";
import CrossCreditSearchHelper from "./CrossCreditSearchHelper";
import { UnitStandardRelationshipModel } from "../Models/UnitStandardRelationshipModel";
import { AssessmentMethod, LMSStatus } from "../Common/Enum";



export default class ProgrammeUnitStandard extends ComponentHelperBase {

    electiveSectionId: string | undefined;

    unitStandard: UnitStandardModel | undefined;
    preReqUnitstandard: UnitStandardModel[] | undefined;
    _resultHelper: Result | undefined;
    _trainingPlanUnitStandard: TrainingPlanUnitStandard | undefined;

    comment: TrainingPlanCommentModel | undefined;

    isCompulsory: boolean | undefined;

    private _resourceStatus: number | undefined;
    commentForm: CommentForm | undefined;
    resultForm: ResultForm | undefined;
    _progUnitStandard: ProgrammeUnitStandardModel;
    _globalStateModel: GlobalStateModel;
    _isChecked: boolean;
    _iselectedInOtherSection: boolean;
    _isExempt:boolean;

    /**
     *
     */
    constructor(progUnitStandard: ProgrammeUnitStandardModel, globalStateModel: GlobalStateModel, isCompulsary: boolean, electiveSectionId: string | undefined) {
        super();
        this._progUnitStandard = progUnitStandard;
        this._progUnitStandard._tokenModel = globalStateModel.tokenModel as TokenModel;
        this._globalStateModel = globalStateModel;
        this.isCompulsory = isCompulsary;
        this._isChecked = false;
        this._iselectedInOtherSection = false;
        this._resourceStatus = -1;;
        this.electiveSectionId = electiveSectionId;
        this._isExempt = false
        this._searchHelper = new CrossCreditSearchHelper(this.electiveSectionId as string, this._globalStateModel, null);

    }

    async build() {
        var unitStandardModel = new UnitStandardModel(this._progUnitStandard._tokenModel)
        var resultModel = new ResultModel(this._progUnitStandard._tokenModel)
        var progUSModel = new ProgrammeUnitStandardModel(this._progUnitStandard._tokenModel);

        var unitStandard = await unitStandardModel.retrieveUnitStandardAsync((this._progUnitStandard._tims_unitstandardid_value as string)) as UnitStandardModel;
        this.unitStandard = unitStandard;

        var result = await resultModel.retrieveResultsForUnitStandardCode((unitStandard.tims_frameworkelementcode as string));
        if (result != undefined)
            this._resultHelper = new Result(result);

        await this.initiateTPURetrieve();
        await this.initializeExemptCheck()

        if (this.unitStandard != null) {
            var selectedUnitStandards = await unitStandardModel.checkIfSelectedInOtherSections(this.unitStandard.tims_frameworkelementcode as string, this._globalStateModel.trainingPlanObj?.tims_trainingplanid as string, this.unitStandard.tims_unitstandardid as string) as UnitStandardModel[];
            this._iselectedInOtherSection = selectedUnitStandards.length > 0 && !this.hasTrainingPlanUnitStandard;
        }

        if (this.hasPrerequisites) {
            let preReqForProgUS = await progUSModel.findPreReqForProgrammeUnitstandard(this._progUnitStandard.tims_programmeunitstandardid as string);
            var resultModel = new ResultModel(this._progUnitStandard._tokenModel);

            this.preReqUnitstandard = [];

            for (let index = 0; index < preReqForProgUS.length; index++) {
                const element = preReqForProgUS[index];

                let preReqUS = new UnitStandardModel(this._progUnitStandard._tokenModel);
                let result = resultModel.retrieveResultsForUnitStandardCode(element["prereqUS.tims_frameworkelementcode"] as string);
                if (result == undefined) {
                    preReqUS.tims_name = element["prereqUS.tims_name"];
                    preReqUS.tims_frameworkelementcode = element["prereqUS.tims_frameworkelementcode"];
                    preReqUS.tims_unitstandardid = element["prereqUS.tims_unitstandardid"];
                    this.preReqUnitstandard.push(preReqUS);
                }
            }
        }

    }

    async previewBuild() {
        var unitStandardModel = new UnitStandardModel(this._progUnitStandard._tokenModel)
        var resultModel = new ResultModel(this._progUnitStandard._tokenModel)

        var unitStandard = await unitStandardModel.retrieveUnitStandardAsync((this._progUnitStandard._tims_unitstandardid_value as string)) as UnitStandardModel;
        this.unitStandard = unitStandard;

        var result = await resultModel.retrieveResultsForUnitStandardCode((unitStandard.tims_frameworkelementcode as string));
        await this.initializeExemptCheck()
        if (result != undefined)
            this._resultHelper = new Result(result);

        if (this.isCompulsory)
            this._isChecked = true;
    }

    async initializeExemptCheck(){
        // #12836 Setting isExempt on Programme Unit Standard so we can see it on plan preview / before a plan is activated

        var USRelationshipModel = new UnitStandardRelationshipModel(this._progUnitStandard._tokenModel)
        var resultModel = new ResultModel(this._progUnitStandard._tokenModel)
        
        let count = 0
        if(this._globalStateModel.programmeObj?.tims_programmeid != null){
            var unitsRequiredForExemption = await USRelationshipModel.retrieveExemptRelationship(this._globalStateModel.programmeObj?.tims_programmeid,  (this.unitStandard?.tims_frameworkelementcode as string))
            // Counting the units achieved from the "pre-us" list on the US relationship
            for (let index = 0; index < unitsRequiredForExemption.length; index ++){
                    let element = unitsRequiredForExemption[index]
                    var result = await resultModel.retrieveResultsForUnitStandardCode(element["unit_standard.tims_frameworkelementcode"] as string) as ResultModel
                    if(result?.statuscode == 3) count++ 
            }

            // There will always only be one unit standard rule for programme / unit standard combination.
            // We can take the first element in the array to check the rule type

            if(unitsRequiredForExemption.length > 0) {

                // True = ANY of the list (OR) - At least one result in pre-us list needs to be achieved
                if(unitsRequiredForExemption[0].tims_rule) this._isExempt = count > 0

                // False = ALL of the list (AND) - All results in pre-us list need to be achieved
                else this._isExempt = count == unitsRequiredForExemption.length
            }
        }
    }


    async initiateTPURetrieve() {
        var trainingPlanUnitStandardModel = new TrainingPlanUnitStandardModel(this._progUnitStandard._tokenModel);
        var commentModel = new TrainingPlanCommentModel(this._progUnitStandard._tokenModel);

        var tpus = await trainingPlanUnitStandardModel.retrieveTPUSFromProgrammeUnitStandard(this._progUnitStandard.tims_programmeunitstandardid as string) as TrainingPlanUnitStandardModel;

        this._trainingPlanUnitStandard = new TrainingPlanUnitStandard(tpus);
        if (tpus != null) {
            let testUS = tpus.unitStandard;
            var tpusId = this._trainingPlanUnitStandard._tpuModel.tims_trainingplanunitstandardid;
            let comment = await commentModel.retrieveByTrainingPlanUnitStandardId(tpusId as string);

            if (comment)
                this.comment = comment
        }

        this.commentForm = new CommentForm(this);
        this.resultForm = new ResultForm(this._resultHelper, this._trainingPlanUnitStandard);
        this._isChecked = this.hasTrainingPlanUnitStandard;
    }

    get resultHelper() {
        return this._resultHelper as Result;
    }

    get searchHelper() {
        return this._searchHelper as CrossCreditSearchHelper;
    }



    get isSelectedOtherSection() {
        return this._iselectedInOtherSection;
    }

    deselectFrameworkElementCode(option: IUSCheckedEventArg) {
        if (this.isCompulsory)
            return;

        if (option.frameworkElementCode == this.unitStandard?.tims_frameworkelementcode &&
            this.electiveSectionId != option.electiveSectionId && !this.isDirty) {
            this.onCheckboxChanged(false);
        }
    };

    // When deselecting, select from the other sections
    selectFrameworkElementCode(option: IUSCheckedEventArg) {

        if (this.isCompulsory)
            return;

        if (option.frameworkElementCode == this.unitStandard?.tims_frameworkelementcode &&
            this.electiveSectionId != option.electiveSectionId && this.isDirty) {
            this.onCheckboxChanged(true);
        }
    };

    get resourceStatus() {
        var tpus = this._trainingPlanUnitStandard;
        if (tpus && tpus.resourceorderstatus) {
            this._resourceStatus = tpus.resourceorderstatus;
        }

        return this._resourceStatus as number;
    }
    // Cannot make changes if result is acheved after training plan
    // start date and is itr registered
    get isITRLocked() {

        var dateOfTrainingPlanStart = this._globalStateModel.trainingPlanObj?.tims_datetrainingplanstarted;
        var dateOfAchievement = this._resultHelper && this._resultHelper._resultModel.tims_dateofachievement;
        var itrRegistrationStatus = this._globalStateModel.trainingPlanObj?.tims_itrregistrationstatus;

        // if has result and is itr registered
        if (dateOfAchievement &&
            dateOfTrainingPlanStart &&
            (itrRegistrationStatus == 2 || itrRegistrationStatus == 3)) {

            var startDate = new Date(dateOfTrainingPlanStart);
            startDate.setHours(0);
            startDate.setMinutes(0);
            startDate.setSeconds(0);

            var dateofAcheive = new Date(dateOfAchievement);

            if (dateofAcheive >= startDate && this.hasTrainingPlanUnitStandard) {
                return true;
            }
        }

        return false;
    };


    createResult() {
        (this.resultForm as ResultForm).isVisible = !this.resultForm?.isVisible;
    };

    createComment() {
        (this.commentForm as CommentForm).isVisible = !this.commentForm?.isVisible;
    };

    // create / remote training plan unit standard
    sync() {
        if (this.isChecked && this.isDirty) {
            this.create();
            this.update();
        } else if (!this.isChecked && this.isDirty) {
            this.delete();
            this.updatePre();
        }
    };

    create() {
        let trainingPlanUnitStandardRepo = new TrainingPlanUnitStandardModel(this._globalStateModel.tokenModel as TokenModel);
        var electiveSectionId = this.electiveSectionId,
            tpus = {
                //trainingplanid: trainingPlanId,
                programmeunitstandardid: this._progUnitStandard.tims_programmeunitstandardid,
                unitstandardid: this.unitStandard?.tims_unitstandardid,
                assessmenttype: this._progUnitStandard.tims_assessmenttype,
                assessedby: this._progUnitStandard.tims_assessedby,
                electivesectionid: ""
            } as ItpuCreate;

        if (electiveSectionId) {
            tpus.electivesectionid = electiveSectionId;
        }
        if(this._globalStateModel.programmeObj?.tims_istradeacademy && this._progUnitStandard.tims_sendtolms){
            tpus.tims_assessmentmethod = AssessmentMethod.Online
            tpus.tims_lmsstatus = LMSStatus.Active
        }
        let seqNum = trainingPlanUnitStandardRepo.getMaxSeqNum();
        if (!this.isSelectedOtherSection) {
            TrainingPlanUnitStandardManager.createTrainingPlanUnitStandard(tpus, seqNum);
        }
    };

    createForCompulsary() {
        let trainingPlanUnitStandardRepo = new TrainingPlanUnitStandardModel(this._globalStateModel.tokenModel as TokenModel);
        var electiveSectionId = this.electiveSectionId,
            tpus = {
                trainingplanid: this._globalStateModel.trainingPlanObj?.tims_trainingplanid,
                programmeunitstandardid: this._progUnitStandard.tims_programmeunitstandardid,
                unitstandardid: this.unitStandard?.tims_unitstandardid,
                assessmenttype: this._progUnitStandard.tims_assessmenttype,
                assessedby: this._progUnitStandard.tims_assessedby,
                electivesectionid: electiveSectionId
            } as ItpuCreate;

        return trainingPlanUnitStandardRepo.create(tpus)
    }


    delete() {
        if (!this.isSelectedOtherSection) {
            TrainingPlanUnitStandardManager.deleteTrainingPlanUnitStandard(this._trainingPlanUnitStandard?._tpuModel as TrainingPlanUnitStandardModel);
        }
    };

    update() {

        if (this._resultHelper && this._resultHelper._resultModel.tims_dateofachievement) {
            var tpus = {
                programmeunitstandardid: this._progUnitStandard.tims_programmeunitstandardid,
                unitstandardid: this.unitStandard?.tims_unitstandardid,
                electivesectionid: this.electiveSectionId
            } as ItpuUpdate;
            return TrainingPlanUnitStandardManager.updateTrainingPlanUnitStandard(tpus);
        }
    };

    updatePre() {
        if (this._resultHelper && this._resultHelper._resultModel.tims_dateofachievement) {
            return TrainingPlanUnitStandardManager.updatePreTrainingPlanUnitStandard(this._trainingPlanUnitStandard?._tpuModel as TrainingPlanUnitStandardModel);
        }
    };

    onCheckboxChanged(isChecked: boolean) {
        this._isChecked = isChecked;
    }


    // get isDisabled() { 
    //     return this.isCompulsory;
    // };

    get isDirty() {
        var hasTPUS = this.hasTrainingPlanUnitStandard;
        return (this.isChecked && !hasTPUS) || (!this.isChecked && hasTPUS);
    };

    get isChecked() {
        return this._isChecked;
    }
    get assessmentType() {
        return {
            1: "Practical",
            2: "Theory",
            3: "Combined"
        }[this._progUnitStandard.tims_assessmenttype as number];
    };

    get credits() {
        return this.unitStandard?.tims_credits as number;
    };

    get isExempt() {
        var tpus = this._trainingPlanUnitStandard;
        if (tpus?._tpuModel && tpus._tpuModel.tims_isexempt || this._isExempt) {
            return true;
        } else {
            return false;
        }
    };

    get hasPrerequisites() {
        return this._progUnitStandard._tims_relationship_value != undefined;
    }

    get excludeCredits() {
        if (this._progUnitStandard.tims_excludecredits != undefined)
            return this._progUnitStandard.tims_excludecredits
        else
            return false;
    }

    get hasResource() {
        var tpus = this._trainingPlanUnitStandard as TrainingPlanUnitStandard;
        if (tpus && tpus._tpuModel != null) {
            return tpus._tpuModel.uspunitstandardid !== undefined;
        }
        else return false;
    };

    get hasTrainingPlanUnitStandard() {
        return this._trainingPlanUnitStandard?._tpuModel !== null && this._trainingPlanUnitStandard?._tpuModel !== undefined
    }

    get isSelected() {
        if (this.isDirty)
            return this.isChecked;

        return this.hasTrainingPlanUnitStandard;
    };

    get isAchieved() {
        var result = this._resultHelper;
        return result !== null && result !== undefined && (result?.isAchieved());
    };

    get isAchievedPendingPreReq() {
        var result = this._resultHelper;
        if (result != undefined)
            return result?.isAchievedPendingPreReq();
        else
            return false;
    };

    get isPartiallyAchieved() {
        var result = this._resultHelper;
        return result !== undefined && (result?.isPartiallyAchieved());
    };

    // Events which keep the comments and results in sync for the different views
    _createComment(options: TrainingPlanCommentModel) {
        if (!this._trainingPlanUnitStandard)
            return;

        if (this._trainingPlanUnitStandard._tpuModel.tims_trainingplanunitstandardid == options._tims_trainingplanunitstandardid_value) {
            this.comment = options as TrainingPlanCommentModel;
        }
    };

    _updateComment(options: TrainingPlanCommentModel) {
        if (!this.comment)
            return;

        var commentId = this.comment.tims_trainingplancommentid;
        if (commentId == options.tims_trainingplancommentid) {
            this.comment = options;
        }
    };

    _createResult(options: ResultModel) {
    };

    _updateResult(options: ResultModel) {
        if (!this._resultHelper)
            return;

        if (options.tims_resultid == this._resultHelper._resultModel.tims_resultid) {
            this._resultHelper = new Result(options);
        }
    };

    _trainingPlanUnitStandardCreated(tpus: TrainingPlanUnitStandardModel) {
        if (this._trainingPlanUnitStandard !== null)
            return;

        var trainingPlanCode = tpus.unitStandard?.tims_frameworkelementcode;
        var isMatchingElective = tpus._tims_electivesectionid_value !== undefined &&
            this.electiveSectionId !== undefined &&
            tpus._tims_electivesectionid_value == this.electiveSectionId;

        if ((this.isCompulsory || isMatchingElective) && this.unitStandard?.tims_frameworkelementcode == trainingPlanCode) {
            this._trainingPlanUnitStandard = new TrainingPlanUnitStandard(tpus);
        }
    };

}