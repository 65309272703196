export enum Templates {
    ItemSelection = 1,
    CrossCreditElective = 2,
    UnitStandardItem = 3,
    ItemSelectionEdit = 4,
    PickAListElective = 5,
    CrossCreditElectiveEdit = 6,
    Reorder = 7,
    Goals = 8
}

export enum Events {
    SaveTrainingPlan = "SAVE_TP",
    UnitStandardSelectionChangedInPrgElecSec = "US_SEL_CHANGED_PRG_ELEC",
    UnitStandardSelectionChangedInElecGrps = "US_SEL_CHANGED_ELEC_GRP",
    TrainingPlanCreated = "TP_CREATED",
    POSTSaveTrainingPlan = "POST_SAVE_TP",
    SetMessageBar = "SET_MESSAGE_BAR",
    SaveOrder = "SAVE_ORDER",
    OpenCreateViewModal = "OPEN_CREATE_VIEW_MODAL",
    OpenSaveViewModal = "OPEN_SAVE_VIEW_MODAL",
    SaveExistingView = "SAVE_EXISTING_VIEW",
    DeleteView = "DELETE_VIEW",
    SetMessageBarWarning = "MESSAGE_BAR_WARNING"
}

export enum RequestType {
    SAVE_TP = 1,
    ACTIVATE_TP = 2,
    CREATE_TEMPLATE = 3,
    APPLIED_TEMPLATE = 4,
    ADD_US = 5,
    ADD_RESULT = 6,
    UPDATE_RESULT = 7,
    ADD_COMMENT = 8,
    UPDATE_COMMENT = 9,
    SEND_EMAIL = 10,
    ADD_ASSESSOR = 11,
    ADD_VERIFIER = 12,
    SAVE_ORDER = 13,
    CREATE_VISIT = 14,
    CREATE_GOAL = 15,
    DELETE_GOAL = 16,
    UPDATE_GOAL = 17,
    UPDATE_VISIT = 18,
    CREATE_VIEW = 19,
    UPDATE_VIEW = 20,
    DELETE_VIEW = 21
}

export enum TrainingPlanStatuses {
    Draft = 1,
    Cancelled = 2,
    Active = 3,
    Completed_by_Trainee = 4,
    Already_Awarded = 5
}

export enum ProgrammeStatuses {
    Pending = 1,
    InActive = 2,
    Active = 3,
    Expiring = 4,
    Expired = 5,
    SubmitToITRForApproval = 6,
    WaitingForApproval = 7,
    UnderMaintenance = 969760000
}

export enum ContactRole {
    Assessor = 1,
    Verifier = 2
}

export enum Views {
    Readonly = 1,
    EditMode = 2,
    Reorder = 3,                
    Course = 4,
    Custom = 5  
}

export enum SortReorderCode {
    None = 1,
    ResultFirst = 2,
    ResultLast = 3,
    ByUnits = 4
}

export enum GoalsStatus {
    Active = 1,
    InActive = 2,
    Completed = 498400000,
    Cancelled = 498400001
}

export enum ReminderType {
    Email = 1,
    TextMessage = 2
}

export enum GoalsCreationMode {
    Create = 1,
    Update = 2,
    Delete = 3,
    UpdateReminders = 4
}

export enum AssessmentStatus {
    SubmittedByLearner = 1,
    Overdue = 2,
    Achieved = 3,
    SentBackForResubmission = 4,            
    ToBeSubmitted = 5,
    ITOAssessing = 6
}

export enum GoalType {
    Learning = 100000000,
    PastoralCare = 100000001,
    Milestone = 100000002
}

export enum ProgrammeDeliveryType {
    Online = 100000000,
    Paper = 100000001,
    Mixed = 100000002
}

export enum AssessmentMethod {
    Paper = 100000000,
    Online = 100000001
}

export enum LMSStatus  {
    Active = 100000001,
    Pending = 100000000,
    Suspended = 100000004,
    Error = 100000002
}

export enum LMSAssessmentMethod {
    Online = 100000000,
    Mixed = 100000001
}