import { useState, useContext, useEffect, useMemo } from "react";
import { DefaultButton } from "@fluentui/react/lib/Button";
import { Dropdown, TextField, IStackItemStyles, DefaultPalette, Modal, Dialog, PrimaryButton, DialogFooter } from "@fluentui/react";
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import {Stack, StackItem } from "@fluentui/react";
import "../assets/css/GoalStatusControl.css";
import Common from '../Common/Common';
import GlobalState from "../GlobalState";
import Emitter from "../Common/EventEmitter";
import TokenModel from "../Models/TokenModel";
import { GoalModel } from "../Models/GoalModel";
import { VisitModel } from "../Models/VisitModel";
import { Events, RequestType, GoalsStatus, GoalsCreationMode, AssessmentMethod, LMSStatus } from '../Common/Enum';
import { IMessagebarObject } from "./MasterControl";
import { goalsControlContext } from "./GoalsControl";
import TrainingPlanUnitStandardModel from "../Models/TrainingPlanUnitStandardModel";
import { ReorderControlContext } from '../Contexts/ReorderControlContext';
import { DialogType } from "@fluentui/react";
import { useBoolean, useId } from "@fluentui/react-hooks";

const stackItemStyles: (width?: number) => IStackItemStyles = (width) => {
  return {
    root: {
      whiteSpace:'normal',
      width: width !== undefined ? width : 'auto',
      display: 'flex',
      padding: 5    
    },
  };
};



const primaryStackItemStyle = stackItemStyles();
const increasedWidthStackItemStyle = stackItemStyles(120);

export const GoalStatusControl: React.FunctionComponent<GoalStatusControlProps> = (props) => {
  const dialogContentProps = {
      type: DialogType.normal,
      title: 'Confirm',
      closeButtonAriaLabel: 'Close',
      subText: 'Cancelling this goal will cause the learner to lose access to this unit in Mahi Tahi. Are you sure you want to proceed?',
  };

  const labelId: string = useId('dialogLabel');
  const subTextId: string = useId('subTextLabel');
  const dialogStyles = { main: { maxWidth: 450 } };
  const modalProps = useMemo(() => ({
    titleAriaId: labelId,
    subtitleAriaId: subTextId,
    isBlocking: false,
    styles: dialogStyles,
  }),
  [labelId, subTextId]);

  const {goal, visit, isLastActiveGoal, showGoalsModal, setGoalsCreationMode} = props
  
  const globalState = useContext(GlobalState);
  const [cancellationReason, setCancellationReason] = useState("");
  const [goalStatus, setGoalStatus] = useState(goal.statuscode)
  const [saving, setSaving] = useState(false)
  const goalsControlDispatcher = useContext(goalsControlContext);
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
  

  const reorderControlContext = useContext(ReorderControlContext)

  if (!reorderControlContext) {
    throw new Error('Reorder control context is not available');
  }
  const {reorderState, reorderDispatcher} = reorderControlContext

  // Only allow editing of reminders if:
  //  - Goal status is active
  //  - Goal status has been saved
  //  - Due date is in the future

  const canManageReminders = () => {
    if(globalState.IsPortalMode) return false
    let dueDate = goal.fus_duedate
    if(dueDate){
      return (
        Common.getDifferenceInDays(new Date(), new Date(dueDate)) > 0 &&
        goalStatus === 1 && 
        goalStatus === goal.statuscode
        ) 
      }
  }

  useEffect(() => {
    if(goal.tims_cancellationreason != null){
      setCancellationReason(goal.tims_cancellationreason)
    }
    // setCancellationReason(goal.tims_cancellationreason ?? "")
  }, [])

  const confirmSaveGoalStatus = async () => {
    let goalModel = new GoalModel(globalState.tokenModel as TokenModel);
    let tpusModel = new TrainingPlanUnitStandardModel(globalState.tokenModel as TokenModel)

    let response = await goalModel.updateGoalStatus(goal.fus_goalid as string, goalStatus as number, cancellationReason);

    if (response.ok) {
      
      let updatedGoal = await goalModel.retrieveGoal(goal.fus_goalid as string);
      let updateObj = { goalObj: updatedGoal, visitId: visit?.tims_macvisitid } as any
      if(goal._tims_trainingplanunitstandard_value){
        let updatedTPUS = await tpusModel.retrieveTPUSFromId(goal._tims_trainingplanunitstandard_value as string, false) as TrainingPlanUnitStandardModel;
        reorderDispatcher({type: "TPUS_UPDATED", obj:{updatedTPU:updatedTPUS}})

        updateObj['tpus'] = updatedTPUS
      }

      if(typeof(goalsControlDispatcher) === 'function'){
        
        goalsControlDispatcher({ type: "POST_GOALS_UPDATE", obj: updateObj });
        Emitter.emit(Events.SetMessageBar, { errors: [], isSuccess: true, requestType: RequestType.UPDATE_GOAL, showMessageBar: true } as IMessagebarObject);
        setSaving(false)
      }
    }
  }

  useEffect(() => {
    if(saving && isLastActiveGoal && goalStatus == GoalsStatus.Cancelled){
      toggleHideDialog()
    }
  }, [saving, goalStatus])


  const saveGoalStatus = async () => {
    setSaving(true)
    if(isLastActiveGoal && goalStatus == GoalsStatus.Cancelled){
      return;
    }

    confirmSaveGoalStatus() 
  }

  // Only allow saving of goal if status or cancellation reason has been changed
  const canSaveGoalStatus = () => {
      if(globalState.IsPortalMode) return false
      let isCancellationReasonDirty =  (cancellationReason && cancellationReason != goal.tims_cancellationreason)
      return isCancellationReasonDirty || goalStatus != goal.statuscode 
  }

  return (
    <>
    <Stack horizontal>
      <StackItem styles={stackItemStyles(80)} >
        <Stack>
          <StackItem  styles={stackItemStyles(80)}>
            <p className="mb-0">Due date:</p>
          </StackItem>
          <StackItem styles = {primaryStackItemStyle} className="show-on-print">
            <p >Status:</p>
          </StackItem>
        </Stack>
      </StackItem>
      <StackItem styles={increasedWidthStackItemStyle}>
        <Stack>
          <StackItem styles={stackItemStyles(130)}>
            <p className="mb-0">{Common.getFormattedValue(goal, "fus_duedate")}</p>
          </StackItem>
          <StackItem styles={primaryStackItemStyle} className="show-on-print">
            <p className="ml-2"> 
            {GoalsStatus[goalStatus as number]}
            {goalStatus === 498400001 && cancellationReason ? " - " + cancellationReason : ""}
            </p>
          </StackItem>
        </Stack>
      </StackItem>
      <StackItem styles={primaryStackItemStyle}>
      <Dropdown
          style={{width:'9vw'}}
          disabled={globalState.IsPortalMode}
          className="hide-on-print"
          options={[
              { key: 1, text: "Active" },
              { key: 498400000, text: "Completed" },
              { key: 498400001, text: "Cancelled" },
            ]}
          selectedKey={goalStatus}
          onChange={(e, option, index) => {
            if (option?.key != undefined && option.key != null) {
              setCancellationReason("");
              setGoalStatus(option.key as number);
          }
        }}/>
      </StackItem>
      {goalStatus === 498400001 && 
          <StackItem styles={primaryStackItemStyle}>
            <TextField
              className="hide-on-print"
              placeholder="Cancellation Reason"
              style={{ marginLeft: "5px", width:'9vw'}}
              value={cancellationReason}
              onChange={(e, text) => {
                if (text != undefined) {
                  setCancellationReason(text);
                }
              }}
            />
          </StackItem>        
    }
      <StackItem styles={primaryStackItemStyle}>
        <DefaultButton
            disabled={!canSaveGoalStatus()}
            className="button-style hide-on-print"
            title="Save"
            iconProps={{ iconName: "Save" }}
            type="button"
            onClick={async (e) => {
              saveGoalStatus();
            }}
          ></DefaultButton>
      </StackItem>
      <StackItem styles={primaryStackItemStyle}>
        {saving ? <Spinner size={SpinnerSize.medium} /> : <></>}
      </StackItem>
      { canManageReminders() && <StackItem styles={primaryStackItemStyle}>
        <DefaultButton onClick={async(e) => {
          setGoalsCreationMode(GoalsCreationMode.UpdateReminders)
          showGoalsModal()  
        }} className="button-style hide-on-print">
            Manage reminders
        </DefaultButton>
      </StackItem> }
    </Stack>
    <Dialog 
        modalProps={modalProps} 
        dialogContentProps={dialogContentProps} 
        hidden={hideDialog}
      >
        <DialogFooter>
        <PrimaryButton onClick={async (e) => {
          toggleHideDialog();
          await confirmSaveGoalStatus()
        }} text="Yes" />
        <DefaultButton text="No" onClick={() => {toggleHideDialog(); setSaving(false)}} />
        </DialogFooter>
    </Dialog>
    </>
    
    );
}

interface GoalStatusControlProps {
    isLastActiveGoal:boolean,
    goal:GoalModel,
    visit:VisitModel,
    showGoalsModal:() => void,
    setGoalsCreationMode:React.Dispatch<GoalsCreationMode>
}