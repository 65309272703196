import { useContext, useEffect, useState, Fragment } from 'react';
import { useBoolean } from '@fluentui/react-hooks';
import { Stack, StackItem, IconButton, DatePicker, DayOfWeek, IStackTokens, DefaultButton, addDays,Dropdown, IDropdownOption, IDropdownStyles, ComboBox, IComboBoxOption, IComboBoxStyles } from '@fluentui/react';
import { useRef } from 'react';
import { IComboBox } from '@fluentui/react';
import Common from '../Common/Common';
import { goalsControlContext } from './GoalsControl';
import GlobalState from "../GlobalState";
import { VisitModel } from "../Models/VisitModel";
import { GoalModel } from '../Models/GoalModel';
import { cancelIcon, contentStyles, iconButtonStyles } from '../Common/ModalHelper';
import { FontIcon } from '@fluentui/react/lib/Icon';
import { mergeStyles } from '@fluentui/react/lib/Styling';

import { GoalReminder } from './GoalReminder';
import TokenModel from '../Models/TokenModel';
import GoalsHelper from '../ComponentHelpers/GoalsHelper';
import { AssessmentMethod, Events, GoalType, LMSAssessmentMethod, LMSStatus, ProgrammeDeliveryType, RequestType } from '../Common/Enum';
import Emitter from "../Common/EventEmitter";
import { IMessagebarObject } from "./MasterControl";
import { GoalsCreationMode } from '../Common/Enum';

import TrainingPlanUnitStandardModel from '../Models/TrainingPlanUnitStandardModel';
import TrainingPlanUnitStandard from '../ComponentHelpers/TrainingPlanUnitStandard';
import { ReorderControlContext } from '../Contexts/ReorderControlContext';

const iconClass = mergeStyles({ fontSize: 20, marginRight:'10px', color:'#5BB4D8' });
const itemAlignmentsStackTokens: IStackTokens = { childrenGap: 5, padding: 5 };



const dropdownStyles: Partial<IComboBoxStyles> = {
  root: { width: 500, marginBottom: 10 }, 
  input: { fontSize: 14 }, 
  callout: { width: 500 }, 
};

export const AddGoalControl: React.FunctionComponent<AddGoalControlProps> = (props) => {
  const comboBoxRef = useRef<IComboBox>(null);

    const { goalsCreationMode, visitModel, goalModel, goalsHelper, units, hideGoalsModal } = props;

    const goalsControlDispatcher = useContext(goalsControlContext);
    const globalState = useContext(GlobalState);

    const reorderControlContext = useContext(ReorderControlContext)

    if (!reorderControlContext) {
      throw new Error('Reorder control context is not available');
    }

    const {reorderState, reorderDispatcher} = reorderControlContext

    const [isGoalCreating, { setTrue: showGoalsLoader, setFalse: hideGoalsLoader }] = useBoolean(false);
    const [goalsDescription, setGoalsDescription] = useState("");
    const [goalCharacterCount, setGoalCharacterCount] = useState(0);
    const [goalsDueDate, setGoalsDueDate] = useState<Date | undefined>();

    const [isWorkplaceReminderChecked, setIsWorkplaceReminderChecked] = useState<boolean>(false)
    const [workplaceReminderTypes, setworkplaceReminderTypes] = useState<number[]>([1])
    const [workplaceReminderDate, setWorkplaceReminderDate] = useState<Date>()

    const [isLearnerReminderChecked, setIsLearnerReminderChecked] = useState<boolean>(false)
    const [learnerReminderTypes, setLearnerReminderTypes] = useState<number[]>([])
    const [learnerReminderDate, setLearnerReminderDate] = useState<Date>()

    const [isTaReminderChecked, setIsTaReminderChecked] = useState<boolean>(false)
    const [taReminderTypes, setTaReminderTypes] = useState<number[]>([])
    const [taReminderDate, setTaReminderDate] = useState<Date>()

    const [selectedGoalType, setGoalType] = useState<number>(100000001)

    const [isLoading, setIsLoading] = useState(true);

    const visitDate = new Date(visitModel.tims_dateofvisit as string);
    const minGoalDate = addDays(visitDate, 1);

    const [selectedUnitStandard, setSelectedUnitStandard] = useState<string | null>(null);
    const [isUnitStandardLocked, setUnitStandardLocked] = useState(false);

    const [selectedAssessmentMethod, setSelectedAssessmentMethod] = useState<number | null>(null)
    const [isAssessmentMethodLocked, setAssessmentMethodDisabled] = useState(false)
    const [isGoalTypeDisabled, setGoalTypeDisabled] = useState(false)

    const [isAssessmentMethodVisible, setIsAssessmentMethodVisible] = useState(false)

    const [assessmentMethodDropdown, setAssessmentMethodDropdown] = useState<IDropdownOption[]>(
      [
        {key: 100000000, text:"Paper Assessment"},
        {key: 100000001, text:"Online Assessment"}
      ]
    )

    let goalDropdowns: IDropdownOption[] = [];
    let goalTypeDropdown: IDropdownOption[] = [
      {key:100000001, text:"Pastoral care"},
      {key:100000000, text:"Learning"},
      {key:100000002, text:"Milestone"},
    ]

    goalDropdowns = units.map((tpus) => ({key:tpus.Id, text:tpus.unitStandard?.tims_name} as IComboBoxOption))


    const goalTypeChanged = (goalType:number) => {
      // Clearing unit standard if no longer learning mode
      if(goalType != GoalType.Learning && selectedUnitStandard != null){
        setSelectedUnitStandard(null)
        setSelectedAssessmentMethod(null)
      }
      setGoalType(goalType)
    }

    const unitStandardChanged = (unitStandardid: string | null) => {
      setSelectedUnitStandard(unitStandardid)

      let unitStandard = units.find(e => e.Id == unitStandardid)
      
      if(unitStandard?._tpuModel.programmeUnitStandard.tims_sendtolms){ 
        setIsAssessmentMethodVisible(true)
        // Limit dropdown options based on assessment method on programme unit standard
        if(unitStandard?._tpuModel.programmeUnitStandard.tims_lmsassessmentmethod != LMSAssessmentMethod.Mixed){
          setAssessmentMethodDropdown([{key: 100000001, text:"Online Assessment"}])
        } else {
          setAssessmentMethodDropdown([{key: 100000000, text:"Paper Assessment"}, {key: 100000001, text:"Online Assessment"}])
        }
        
        // If TPUS already has asssessment method, default and grey out
        if(unitStandard?._tpuModel.tims_assessmentmethod){
          setSelectedAssessmentMethod(unitStandard?._tpuModel.tims_assessmentmethod)
          setAssessmentMethodDisabled(true)
        } else {
          setSelectedAssessmentMethod(null)
          setAssessmentMethodDisabled(false)
        }
      }
      else {
        setIsAssessmentMethodVisible(false)
      } 

      // Default assessment method to online if online programme
      if(globalState.programmeObj?.tims_deliverytype == ProgrammeDeliveryType.Online) setSelectedAssessmentMethod(AssessmentMethod.Online)
    }


    // Set default UI values for editing existing goals
    // When editing an existing goal, this useEffect hook initializes the UI elements
    // with default values to ensure the user sees the current state of the goal they are modifying.
    useEffect(() => {
            if (goalModel) {
                if(goalModel.tims_goaltype){
                  setGoalType(goalModel.tims_goaltype)
                  setGoalTypeDisabled(true)
                }
                if(goalModel._tims_trainingplanunitstandard_value){
                  setSelectedUnitStandard(goalModel._tims_trainingplanunitstandard_value)
                  unitStandardChanged(goalModel._tims_trainingplanunitstandard_value)
                  setUnitStandardLocked(true)
                }
                if (goalModel.fus_duedate) {
                    setGoalsDueDate(new Date(goalModel.fus_duedate));
                }
                if (goalModel.fus_description) {
                    setGoalsDescription(goalModel.fus_description);
                    setGoalCharacterCount(goalModel.fus_description.length);
                }
                if (goalModel.tims_workplacereminderdate) {
                    setIsWorkplaceReminderChecked(true);
                    setWorkplaceReminderDate(new Date(goalModel.tims_workplacereminderdate));
                }
                if (goalModel.tims_learnerreminderdate) {
                    setIsLearnerReminderChecked(true);
                    setLearnerReminderDate(new Date(goalModel.tims_learnerreminderdate));
                    setLearnerReminderTypes(goalModel.tims_learnerremindertype?.split(",").map(Number) || [])
                }
                if (goalModel.tims_trainingadviserreminderdate) {
                    setIsTaReminderChecked(true);
                    setTaReminderDate(new Date(goalModel.tims_trainingadviserreminderdate));
                    setTaReminderTypes(goalModel.tims_trainingadviserremindertype?.split(",").map(Number) || [])
                }
            }
            setIsLoading(false)
    }, [])

    // Disable save button if information has not been populated
    const canSaveGoal = () => {
      const isWorkplaceValid = !isWorkplaceReminderChecked || (workplaceReminderDate && workplaceReminderTypes.length > 0);
      const isLearnerValid = !isLearnerReminderChecked || (learnerReminderDate && learnerReminderTypes.length > 0);
      const isTaValid = !isTaReminderChecked || (taReminderDate && taReminderTypes.length > 0);
      const isDescriptionValid = goalsDescription.trim() !== "";
      let trainingPlanUnitStandard = units.find(e => e.Id == selectedUnitStandard)?._tpuModel

      const isAssessmentMethodRequired = trainingPlanUnitStandard?.programmeUnitStandard.tims_sendtolms && selectedGoalType == 100000000
      const isUnitStandardRequired = selectedGoalType == 100000000
      
      const isUnitStandardValid = selectedUnitStandard != null || !isUnitStandardRequired
      const isAssessmentMethodValid = selectedAssessmentMethod != null || !isAssessmentMethodRequired
      
      return isWorkplaceValid && isLearnerValid && isTaValid && isDescriptionValid && !isGoalCreating && goalsDueDate && isUnitStandardValid && isAssessmentMethodValid;
    };

    // Create or update goal, dependant on goalsCreationMode
    const saveGoal = async () => {
      showGoalsLoader();
      let newGoalModel = new GoalModel(globalState.tokenModel as TokenModel);
      let newTpusModel = new TrainingPlanUnitStandardModel(globalState.tokenModel as TokenModel)
      let visitId = visitModel.tims_macvisitid as string;
      var createdGoal = {} as GoalModel;
      var updatedTPUS = {} as TrainingPlanUnitStandardModel;

      let trainingPlanUnitStandard = units.find(e => e.Id == selectedUnitStandard)?._tpuModel

      if (goalsCreationMode == GoalsCreationMode.Create){
          createdGoal = await goalsHelper.createGoal(goalsDescription, goalsDueDate as Date, visitId, selectedUnitStandard as string, taReminderTypes, learnerReminderTypes, taReminderDate, workplaceReminderDate, learnerReminderDate, selectedGoalType as number) as GoalModel;
          
          if(trainingPlanUnitStandard?.programmeUnitStandard.tims_sendtolms && selectedUnitStandard && (!isAssessmentMethodLocked || trainingPlanUnitStandard?.tims_lmsstatus == LMSStatus.Suspended) ){ // only update if assessment method has been updated or is currently suspended
            let lmsStatus = selectedAssessmentMethod ? AssessmentMethod.Online : null;
            let tpusUpdateResponse = await goalsHelper.updateTpus(selectedUnitStandard as string, selectedAssessmentMethod as number, lmsStatus as number) 
            if (!tpusUpdateResponse.ok){
              updatedTPUS = await tpusUpdateResponse.json() as TrainingPlanUnitStandardModel;
            }
          }
      }
      else {
        let updateResponse = await goalsHelper.updateGoal(goalsDescription, goalsDueDate as Date, goalModel?.fus_goalid as string, selectedUnitStandard as string, taReminderTypes, learnerReminderTypes, taReminderDate, workplaceReminderDate, learnerReminderDate, selectedGoalType as number);
        if (!updateResponse.ok) createdGoal = await updateResponse.json() as GoalModel;
      }
      let requestType = goalsCreationMode == GoalsCreationMode.Create ? RequestType.CREATE_GOAL : RequestType.UPDATE_GOAL;
      if (createdGoal.error != undefined){
        Emitter.emit(Events.SetMessageBar, { errors: [createdGoal.error.message], isSuccess: false, requestType: requestType, showMessageBar: true } as IMessagebarObject);
      }
      else {
        Emitter.emit(Events.SetMessageBar, { errors: [], isSuccess: true, requestType: requestType, showMessageBar: true } as IMessagebarObject);
        
        if(selectedUnitStandard){
          updatedTPUS = await newTpusModel.retrieveTPUSFromId(selectedUnitStandard as string, false) as TrainingPlanUnitStandardModel;
          reorderDispatcher({type: "TPUS_UPDATED", obj:{updatedTPU:updatedTPUS}})
        }

        if(typeof goalsControlDispatcher == "function"){
          if (goalsCreationMode == GoalsCreationMode.Create) {
            createdGoal = await newGoalModel.retrieveGoal(createdGoal.fus_goalid as string);
            if(selectedUnitStandard){
              updatedTPUS = await newTpusModel.retrieveTPUSFromId(selectedUnitStandard as string, false) as TrainingPlanUnitStandardModel;
            }
            goalsControlDispatcher({ type: "POST_GOALS_CREATE", obj: { goal: createdGoal, visitId: visitId, tpus:updatedTPUS } });
          }
          else {
            let updatedGoal = await newGoalModel.retrieveGoal(goalModel?.fus_goalid as string);
            goalsControlDispatcher({ type: "POST_GOALS_UPDATE", obj: { goalObj: updatedGoal, visitId: visitId } });
          }
        }
      }
      hideGoalsLoader();
      hideGoalsModal();
    }

    return (
        <>
          <div className={contentStyles.header}>
            <span id="Add_Goals">{goalsCreationMode == GoalsCreationMode.Create ? "Create Goal" : "Update Goal"}</span>
            {isGoalCreating ? 
              <div style={{ marginLeft: "10px" }} className="lds-dual-ring"></div> : ""}
                <IconButton styles={iconButtonStyles} iconProps={cancelIcon} ariaLabel="Close popup modal" onClick={hideGoalsModal}/>
              </div>
            {!isLoading &&
              <Stack className={contentStyles.body}>
                <StackItem>
                  <Dropdown
                      placeholder="Goal Type"
                      options={goalTypeDropdown}
                      styles={dropdownStyles}
                      selectedKey={selectedGoalType}
                      disabled={isGoalTypeDisabled}
                      onChange={(e, option, index) => {
                        if (option?.key != undefined && option.key != null)
                            goalTypeChanged(option.key as number)
                        }}/>
                    {selectedGoalType == GoalType.Learning ? 
                      <ComboBox
                      allowFreeInput
                        componentRef={comboBoxRef}
                        placeholder="Unit standard / Skill standard"
                        options={goalDropdowns}
                        styles={dropdownStyles}
                        selectedKey={selectedUnitStandard}
                        disabled={isUnitStandardLocked}
                        onChange={(e, option, index) => {
                          if (option?.key != undefined && option.key != null)
                              unitStandardChanged(option?.key as string | null)
                          }}/> : <></>
                          
                          }
                    {isAssessmentMethodVisible ?
                        <Dropdown
                          placeholder="Assessment method"
                          options={assessmentMethodDropdown}
                          styles={dropdownStyles}
                          selectedKey={selectedAssessmentMethod}
                          disabled={isAssessmentMethodLocked}
                          onChange={(e, option, index) => {
                           if (option?.key != undefined && option.key != null)
                               setSelectedAssessmentMethod(option?.key as number | null)
                            }}/>
                            :<></> }
                  </StackItem>
                <StackItem>
                  <textarea 
                    disabled = {goalsCreationMode == GoalsCreationMode.UpdateReminders} 
                    style={{ width: "500px", height: "250px" }} 
                    maxLength={4000} 
                    rows={3} 
                    placeholder="Comment" 
                    value={goalsDescription} 
                    onChange={(e) => {
                      setGoalCharacterCount(e.target.value.length);
                      setGoalsDescription(e.target.value)
                    }}/>
                  <br/>
                  <b>Text limit:</b> 
                  {goalCharacterCount} / 4000
                </StackItem>
                <StackItem>
                  <b>Due Date:</b>
                  <DatePicker 
                    disabled={goalsCreationMode == GoalsCreationMode.UpdateReminders} 
                    value={goalsDueDate} 
                    firstDayOfWeek={DayOfWeek.Sunday} 
                    minDate={minGoalDate} 
                    onSelectDate={(date) => {
                      if (date != null && date != undefined) setGoalsDueDate(date);
                    }} ariaLabel="Select a date" />
                </StackItem>
                {goalsDueDate !== undefined && (
                <StackItem>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {Common.getDifferenceInDays(new Date(), goalsDueDate) >= 0 ? (  
                    <Fragment>
                      <FontIcon aria-label="Info" iconName="Info" className={iconClass} style={{ marginRight: '5px', marginTop: '1px' }} />
                        This due date is {Common.getDifferenceInDays(new Date(), goalsDueDate)} day{Common.getDifferenceInDays(new Date(), goalsDueDate) !== 1 ? 's' : ''} from today
                    </Fragment>
                    )  : (
                    <Fragment>
                      <FontIcon aria-label="Info" iconName="Info" className={iconClass} style={{ marginRight: '5px', marginTop: '1px' }} />
                      <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                        This due date is in the past
                      </span>
                    </Fragment>
                    )}
                  </div>
                </StackItem>
              )}
              <p><b>Goal Reminders:</b></p>
              <StackItem tokens={itemAlignmentsStackTokens}>
                <GoalReminder
                  reminderName="Workplace contact"
                  reminderDate={workplaceReminderDate}
                  reminderTypeOptions = {[ {key:1, text:"Email"} ]}
                  dueDate={goalsDueDate}
                  isChecked={isWorkplaceReminderChecked}
                  reminderTypes={workplaceReminderTypes}
                  setIsChecked={setIsWorkplaceReminderChecked}
                  setReminderTypes={setworkplaceReminderTypes}
                  setReminderDate={setWorkplaceReminderDate} />
              </StackItem>
              <StackItem tokens={itemAlignmentsStackTokens}>
                <GoalReminder
                  reminderName="Learner"
                  reminderDate={learnerReminderDate}
                  reminderTypeOptions = {[ {key:1, text:"Email"}, {key:2, text:"Text Message"} ]}
                  dueDate={goalsDueDate}
                  isChecked={isLearnerReminderChecked}
                  reminderTypes={learnerReminderTypes}
                  setIsChecked={setIsLearnerReminderChecked}
                  setReminderTypes={setLearnerReminderTypes}
                  setReminderDate={setLearnerReminderDate}/>
              </StackItem>
              <StackItem tokens={itemAlignmentsStackTokens}>
                <GoalReminder
                  reminderName="Training Adviser"
                  reminderDate={taReminderDate}
                  reminderTypeOptions = {[ {key:1, text:"Email"}, {key:2, text:"Text Message"} ]}
                  dueDate={goalsDueDate}
                  isChecked={isTaReminderChecked}
                  reminderTypes={taReminderTypes}
                  setIsChecked={setIsTaReminderChecked}
                  setReminderTypes={setTaReminderTypes}
                  setReminderDate={setTaReminderDate}/>
              </StackItem>
              <StackItem>
                <DefaultButton 
                disabled={!canSaveGoal()} 
                style={{ float: "right", marginTop: "7%" }} 
                className="button-style" 
                onClick={async (e) => {saveGoal()}}>Save</DefaultButton>
          </StackItem>
        </Stack> }
      </>
    )
}

interface AddGoalControlProps {
  goalsCreationMode:GoalsCreationMode
  visitModel:VisitModel,
  goalModel?:GoalModel
  goalsHelper:GoalsHelper,
  units:TrainingPlanUnitStandard[],
  hideGoalsModal:() => void;
}