import TrainingPlanUnitStandardModel from "../Models/TrainingPlanUnitStandardModel";
import TrainingPlanModel from "../Models/TrainingPlanModel";
import GlobalStateModel from "..//Models/GlobalStateModel";
import TokenModel from "../Models/TokenModel";
import Emitter from "../Common/EventEmitter";
import { Events } from "../Common/Enum";
import { Serializable } from "../Models/Serializable";

export interface ItpuUpdate {
    programmeunitstandardid: string | undefined | null;
    unitstandardid: string | undefined | null;
    electivesectionid: string | undefined | null;
    resourceorderstatus: number | undefined | null;
    assessorId: string | undefined | null;
    verifierId: string | undefined | null;
    sequencenumber: number | undefined;
    assessmentmethod:number | undefined,
    lmsstatus:number | undefined
}

export interface PostSaveResponse {

    programmeunitstandardid: string | undefined | null;
    unitstandardid: string | undefined | null;
    electivesectionid: string | undefined | null;
    trainingPlanUnitStandard: TrainingPlanUnitStandardModel;
}

export interface ItpuCreate {
    unitstandardid: string;
    assessmenttype: number;
    assessedby: number;
    electivesectionid: string;
    trainingplanid: string;
    programmeunitstandardid: string;
    sequenceNumber: number | undefined;
    tims_lmsstatus:number | undefined;
    tims_assessmentmethod:number | undefined;
}

class TrainingPlanUnitStandardManager {
    private static _instance: TrainingPlanUnitStandardManager;

    private constructor() {
        this.creations = [];
        this.deletions = [];
        this.updates = [];
        this.updatesPre = [];
        this.saveResponse = [];
    }


    private creations: ItpuCreate[];
    private deletions: TrainingPlanUnitStandardModel[];
    private updates: ItpuUpdate[];
    private updatesPre: TrainingPlanUnitStandardModel[];
    private saveResponse: PostSaveResponse[];

    clear() {
        this.creations = [];
        this.deletions = [];
        this.updates = [];
        this.updatesPre = [];
        this.saveResponse = [];
    }

    createTrainingPlanUnitStandard(tpus: ItpuCreate, maxSeqNum: number | undefined) {
        let obj = this.creations.find(item => item.unitstandardid == tpus.unitstandardid && item.programmeunitstandardid == tpus.programmeunitstandardid && item.electivesectionid == tpus.electivesectionid);
        if (obj == undefined) {
            if (maxSeqNum == undefined) {
                //no order has been initialized.skip assigning sequnce number.Initialiazing will be done when user switch to reorder view
            }
            else {
                if (this.creations.length == 0)
                    tpus.sequenceNumber = maxSeqNum + 1;
                else
                    tpus.sequenceNumber = (maxSeqNum + 1) + this.creations.length;
            }


            this.creations.push(tpus);
        }
    }

    createCrossCreditTrainingPlanUnitStandard(tpus: ItpuCreate) {
        let obj = this.creations.find(item => item.unitstandardid == tpus.unitstandardid && item.electivesectionid == tpus.electivesectionid);
        if (obj == undefined)
            this.creations.push(tpus);
    }

    deleteTrainingPlanUnitStandard(tpus: TrainingPlanUnitStandardModel) {
        let obj = this.deletions.find(item => item.tims_trainingplanunitstandardid == tpus.tims_trainingplanunitstandardid);
        if (obj == undefined)
            this.deletions.push(tpus);
    }

    updateTrainingPlanUnitStandard(tpus: ItpuUpdate) {
        let obj = this.updates.find(item => item.unitstandardid == tpus.unitstandardid && item.programmeunitstandardid == tpus.programmeunitstandardid && item.electivesectionid == tpus.electivesectionid);
        if (obj == undefined)
            this.updates.push(tpus);
    }

    updatePreTrainingPlanUnitStandard(tpus: TrainingPlanUnitStandardModel) {
        this.updatesPre.push(tpus);
    }

    private async updateTpus(context: GlobalStateModel) {
        let resps: Response[] = [];
        let promiseArr = [];
        let tpuModel = new TrainingPlanUnitStandardModel(context.tokenModel as TokenModel);
        for (let index = 0; index < this.updates.length; index++) {
            const updateTPU = this.updates[index];

            const preUpdateTPU = this.updatesPre.find((val, index) => {
                return updateTPU.unitstandardid == val._tims_unitstandardid_value;
            });
            if (preUpdateTPU != undefined) {
                promiseArr.push(tpuModel.updateTPUS(preUpdateTPU.tims_trainingplanunitstandardid as string, updateTPU));
                let tp = await tpuModel.retrieveTPUSFromId(preUpdateTPU.tims_trainingplanunitstandardid as string);
                this.saveResponse.push({
                    electivesectionid: updateTPU.electivesectionid,
                    programmeunitstandardid: updateTPU.programmeunitstandardid, unitstandardid: updateTPU.unitstandardid, trainingPlanUnitStandard: tp
                } as PostSaveResponse);
            }

        }

        if (promiseArr.length > 0)
            resps = await Promise.all(promiseArr);

        return resps;

    }

    private async createTpus(context: GlobalStateModel) {
        let tpuModel = new TrainingPlanUnitStandardModel(context.tokenModel as TokenModel);
        let promiseArr = [];
        for (let index = 0; index < this.creations.length; index++) {
            const createdTPU = this.creations[index];
            console.log('created tpu', createdTPU)
            createdTPU.trainingplanid = context.trainingPlanObj?.tims_trainingplanid as string;
            promiseArr.push(tpuModel.create(createdTPU));
        }

        if (promiseArr.length > 0) {
            let createdTpus = await Promise.all(promiseArr) as TrainingPlanUnitStandardModel[];
            for (let index = 0; index < createdTpus.length; index++) {
                const crtdTPU = createdTpus[index] as TrainingPlanUnitStandardModel;
                let tpuCreate = this.creations.find((item) => {

                    if (item.programmeunitstandardid !== undefined)
                        return item.electivesectionid == crtdTPU?._tims_electivesectionid_value && item.unitstandardid == crtdTPU._tims_unitstandardid_value && item.programmeunitstandardid == crtdTPU._tims_programmeunitstandardid_value
                    else
                        return item.electivesectionid == crtdTPU?._tims_electivesectionid_value && item.unitstandardid == crtdTPU._tims_unitstandardid_value
                });
                if (tpuCreate != undefined) {
                    this.saveResponse.push({
                        electivesectionid: tpuCreate.electivesectionid,
                        programmeunitstandardid: tpuCreate.programmeunitstandardid, unitstandardid: tpuCreate.unitstandardid, trainingPlanUnitStandard: crtdTPU
                    } as PostSaveResponse);

                }
            }
            return createdTpus;
        }
    }

    private async deleteTpus(context: GlobalStateModel) {
        let resps: { isSuccess: boolean; exception: Serializable; }[] = [];
        let promiseArr = [];
        let tpuModel = new TrainingPlanUnitStandardModel(context.tokenModel as TokenModel);

        for (let index = 0; index < this.deletions.length; index++) {
            const deleteTpu = this.deletions[index];

            const UpdateTPU = this.updates.find((val, index) => {
                return deleteTpu._tims_unitstandardid_value == val.unitstandardid;
            });

            if (UpdateTPU == undefined)     
                promiseArr.push(tpuModel.deleteTPUS(deleteTpu.tims_trainingplanunitstandardid as string));
        }

        if (promiseArr.length > 0)
            resps = await Promise.all(promiseArr);

        return resps;

    }


    async commit(context: GlobalStateModel, isTemplateMode: Boolean) {
        let respArray = await this.updateTpus(context);
        let statusArr = await this.deleteTpus(context);
        let createStatusArr = await this.createTpus(context);
        let errorsArr: string[] = [];
        let updateSuccessfull = true, deleteSuccessFull = true, createSuccessfull = true;

        if (this.updates.length > 0) {
            updateSuccessfull = respArray.every(item => item.ok);
            let updatesWithError = respArray.filter(item => !item.ok);
            updatesWithError.forEach(async item => {
                let obj = await item.json() as Serializable;
                errorsArr.push(obj.error?.message as string);
            });
        }
        if (this.deletions.length) {
            deleteSuccessFull = statusArr.every(item => item.isSuccess);
            let deletesWithError = statusArr.filter(item => !item.isSuccess);
            deletesWithError.forEach(item => errorsArr.push(item.exception.error?.message as string));
        }
        if (this.creations.length > 0) {
            createSuccessfull = this.saveResponse.length == this.creations.length;
            if (!createSuccessfull) {
                createStatusArr?.filter(item => item.error != undefined).forEach(item => errorsArr.push(item.error?.message as string));
            }
        }

        if ((this.updates.length > 0 || this.creations.length > 0 || this.deletions.length > 0) && !isTemplateMode) {
            if (updateSuccessfull && deleteSuccessFull && createSuccessfull) {
                const tpModel = new TrainingPlanModel(context.tokenModel as TokenModel);
                let tpuModel = new TrainingPlanUnitStandardModel(context.tokenModel as TokenModel);
                tpuModel.executeActionForTpComplete(context.trainingPlanObj?.tims_trainingplanid as string);
                await tpModel.setUpdateITR(context.trainingPlanObj?.tims_trainingplanid as string);
                Emitter.emit(Events.POSTSaveTrainingPlan, this.saveResponse);
            }
        }
        return { isSuccess: updateSuccessfull && deleteSuccessFull && createSuccessfull, errors: errorsArr };

    }
    public static get Instance() {
        // Do you need arguments? Make it a regular static method instead.
        return this._instance || (this._instance = new this());
    }
}
const trainingPlanUnitStandardMgrInstance = TrainingPlanUnitStandardManager.Instance;
export default trainingPlanUnitStandardMgrInstance;