
import { Checkbox, ContextualMenu, DatePicker, DayOfWeek, DefaultButton, Dialog, DialogContent, DialogFooter, DialogType, Dropdown, IconButton, IDragOptions, IDropdownOption, IDropdownStyles, Modal, PrimaryButton, Stack, StackItem, TextField } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Loader from "react-loader-spinner";
import { Events, RequestType } from "../Common/Enum";
import GoalsAndVisits from "../ComponentHelpers/GoalsAndVisits";
import GoalsHelper from "../ComponentHelpers/GoalsHelper";
import GlobalState from "../GlobalState";
import { GridControl } from "./GridControl";
import { useBoolean, useForceUpdate, useId, useConst } from '@fluentui/react-hooks';
import { cancelIcon, contentStyles, iconButtonStyles } from '../Common/ModalHelper';
import cloneDeep from "lodash/cloneDeep";
import { VisitModel, VisitsStatusCode } from "../Models/VisitModel";
import Emitter from "../Common/EventEmitter";
import { IMessagebarObject } from "./MasterControl";
import { format, setHours } from 'date-fns'
import { Serializable } from "../Models/Serializable";
import TokenModel from "../Models/TokenModel";
import { ContactModel } from "../Models/ContactModel";
import { AppointmentModel } from "../Models/AppointmentModel";
import GlobalStateModel from "../Models/GlobalStateModel";
import { DateTimePicker} from "@progress/kendo-react-dateinputs";
import { Popup, PopupProps } from "@progress/kendo-react-popup";
import ComponentHelperBase from "../ComponentHelpers/ComponentHelperBase";
import { TooltipHost, ITooltipHostStyles } from '@fluentui/react/lib/Tooltip';
import { VisitGridControl } from "./VisitsGridControl";
import Common from "../Common/Common";
import TrainingPlanUnitStandard from "../ComponentHelpers/TrainingPlanUnitStandard";

interface GoalsContState {
    goalsHelper: GoalsHelper;
    showLoader: boolean;
}

enum VisitModalMode {
    Edit = 1,
    Create = 2
}
// Set zIndex for date of next visit popup so it shows over details section

const DateOfNextVisitPopUp = (props: PopupProps) => {
    return (
      <Popup
        {...props} style={{zIndex:'960'}}
      />
    );
  };

export const goalsControlContext = React.createContext({});

export const GoalsControl: React.FunctionComponent<{}> = () => {

    const visitTypeDD: IDropdownOption[] = [
        { key: 1, text: "Workplace visit" },
        { key: 2, text: "Class visit" },
        { key: 3, text: "Phone/Video visit" },
        { key: 4, text: "Email" },
        { key: 5, text: "Other" }
    ];



    const showLastDD: IDropdownOption[] = [
        { key: 3, text: "3" },
        { key: 4, text: "4" },
        { key: 5, text: "5" },
        { key: 6, text: "6" },
        { key: 7, text: "7" }
    ]

        const goalStatusDD: IDropdownOption[] = [
            {key: 1, text:"Active"},
            {key: 498400000, text:"Completed"},
            {key: 498400001, text:"Cancelled"},
        ]

    const dragOptions = {
        moveMenuItemText: 'Move',
        closeMenuItemText: 'Close',
        menu: ContextualMenu,
        keepInBounds: true,
    };
    const [isDraggable, { toggle: toggleIsDraggable }] = useBoolean(false);
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const [hideDialogDateValidation, { toggle: toggleHideDialogDateValidation }] = useBoolean(true);
    const [isFinalisingVisit, { toggle: toggleFinalisingVisit }] = useBoolean(false);
    const labelId: string = useId('dialogLabel');
    const subTextId: string = useId('subTextLabel');
    const dialogStyles = { main: { maxWidth: 450 } };
    const tooltipId = useId('tooltip');
    const tooltipCalloutProps = { gapSpace: 0 };
const tooltipHostStyles: Partial<ITooltipHostStyles> = { root: { display: 'inline-block' } };
    const dialogContentPropsForDateValidation = {
        type: DialogType.normal,
        title: 'Error',
        closeButtonAriaLabel: 'Close',
        subText: 'Please make sure pending visit date is not in future.',
    };
    const modalProps = React.useMemo(
        () => ({
            titleAriaId: labelId,
            subtitleAriaId: subTextId,
            isBlocking: false,
            styles: dialogStyles,
            dragOptions: isDraggable ? dragOptions : undefined,
        }),
        [isDraggable, labelId, subTextId],
    );

    const [isAddVisitModalOpen, { setTrue: showVisitsModal, setFalse: hideVisitsModal }] = useBoolean(false);

    const [visitType, setVisitType] = useState(1);
    const [visitDate, setVisitDate] = useState(new Date);
    const [nextVisitDate, setNextVisitDate] = useState(setHours(new Date().setHours(0, 0, 0, 0), 9));
    const [visitTypeFilter, setVisitTypeFilter] = useState<number[]>([1, 2, 3, 4, 5]);
    const [goalStatusFilter, setGoalStatusFilter] = useState<number[]>([1, 498400000, 498400001]);
    const [showLast, setShowLast] = useState(4);
    const [otherVisitNotes, setOtherVisitNotes] = useState<string | undefined>(undefined);
    const [errorMessageForOther, setErrorMessageForOther] = useState<string | undefined>(undefined);
    const [visitModalMode, setVisitModalMode] = useState<VisitModalMode | undefined>(undefined);
    const [visitIdForUpdate, setVisitIdForUpdate] = useState<string | undefined>(undefined);
    const today = useConst(new Date(Date.now()));
    const excludedVisitTypes = [4, 5]
    const dropdownStyles: Partial<IDropdownStyles> = {
        dropdown: { width: 300 },
    };

    //Next visit not required checkbox
    const [isClearNextVisitDate, setIsClearNextVisitDate] = React.useState(false);
    const onClearNextVisitDateChange = React.useCallback(
        (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
            setIsClearNextVisitDate(!!checked);
        },
        [],
    );

    const [isKeepExistingVisitDate, setIsKeepExistingVisitDate] = React.useState(false)
    const onKeepExistingVisitDateChange = React.useCallback(
        (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
            setIsKeepExistingVisitDate(!!checked);
        },
        [],
    );

    const dialogContentProps = {
        type: DialogType.normal,
        title: 'Confirm',
        closeButtonAriaLabel: 'Close',
        subText: isClearNextVisitDate || isKeepExistingVisitDate ? 'Do you want to finalise this visit?' : 'Do you want to finalise this visit? Please make sure you have selected next visit date',
    };

    const globalState = React.useContext(GlobalState);
    const [isVisitCreating, { setTrue: showVisitsLoader, setFalse: hideVisitsLoader }] = useBoolean(false);



    const goalsStateReducer = (state: GoalsContState, action: any) => {

        switch (action.type) {
            case "INIT":
                return { goalsHelper: action.obj.goalsHelper, showLoader: false } as GoalsContState;
            case "SHOW_LOADER":
                let clone = cloneDeep(state.goalsHelper);
                return { goalsHelper: clone, showLoader: true } as GoalsContState;
            case "POST_VISIT_CREATE":
                {
                    let clone = cloneDeep(state.goalsHelper);
                    let goalsAndVisit = new GoalsAndVisits(action.obj.visit);
                    clone.rows.push(goalsAndVisit);
                    return { goalsHelper: clone, showLoader: false } as GoalsContState;
                }
            case "POST_GOALS_CREATE":
                {

                    let clone = cloneDeep(state.goalsHelper);
                    
                    if(action.obj.tpus){
                        for(let index = 0; index < clone.availableTpus.length; index++){
                            const element = clone.availableTpus[index];
                            let tpus = (element as TrainingPlanUnitStandard)
                            if(tpus._tpuModel.tims_trainingplanunitstandardid == action.obj.tpus.tims_trainingplanunitstandardid){
                                tpus.tpuModel = action.obj.tpus
                                break;
                            }
                        }
                    }

                    for (let index = 0; index < clone.rows.length; index++) {
                        const element = clone.rows[index];
                        let goalsAndVisits = (element as GoalsAndVisits);
                        if (goalsAndVisits._visit.tims_macvisitid == action.obj.visitId) {
                            if (goalsAndVisits._visit.goals != undefined)
                                goalsAndVisits._visit.goals.push(action.obj.goal);
                            else
                                goalsAndVisits._visit.goals = [action.obj.goal];

                        }

                    }
                    return { goalsHelper: clone, showLoader: false } as GoalsContState;
                }
            case "POST_GOALS_DELETE":
                {
                    let clone = cloneDeep(state.goalsHelper);

                    if(action.obj.tpus){
                        for(let index = 0; index < clone.availableTpus.length; index++){
                            const element = clone.availableTpus[index];
                            let tpus = (element as TrainingPlanUnitStandard)
                            if(tpus._tpuModel.tims_trainingplanunitstandardid == action.obj.tpus.tims_trainingplanunitstandardid){
                                tpus.tpuModel = action.obj.tpus
                                break;
                            }
                        }
                    }
                    for (let index = 0; index < clone.rows.length; index++) {
                        const element = clone.rows[index];
                        let goalsAndVisits = (element as GoalsAndVisits);
                        if (goalsAndVisits._visit.tims_macvisitid == action.obj.visitId) {
                            if (goalsAndVisits._visit.goals != undefined) {
                                let index = goalsAndVisits._visit.goals.findIndex(item => item.fus_goalid == action.obj.goalId);
                                if (index != -1) {
                                    goalsAndVisits._visit.goals.splice(index, 1);
                                    break;
                                }
                            }
                        }

                    }
                    return { goalsHelper: clone, showLoader: false } as GoalsContState;
                }
            case "POST_GOALS_UPDATE":
                {
                    let clone = cloneDeep(state.goalsHelper);
                    if(action.obj.tpus){
                        for(let index = 0; index < clone.availableTpus.length; index++){
                            const element = clone.availableTpus[index];
                            let tpus = (element as TrainingPlanUnitStandard)
                            if(tpus._tpuModel.tims_trainingplanunitstandardid == action.obj.tpus.tims_trainingplanunitstandardid){
                                tpus.tpuModel = action.obj.tpus
                                break;
                            }
                        }
                    }

                    for (let index = 0; index < clone.rows.length; index++) {
                        const element = clone.rows[index];
                        let goalsAndVisits = (element as GoalsAndVisits);
                        if (goalsAndVisits._visit.tims_macvisitid == action.obj.visitId) {
                            if (goalsAndVisits._visit.goals != undefined) {
                                let index = goalsAndVisits._visit.goals.findIndex(item => item.fus_goalid == action.obj.goalObj.fus_goalid);
                                if (index != -1) {
                                    goalsAndVisits._visit.goals[index] = action.obj.goalObj;
                                    break;
                                }
                            }
                        }
                    }
                    return { goalsHelper: clone, showLoader: false } as GoalsContState;
                }

            case "POST_VISIT_UPDATE":
                {
                    let clone = cloneDeep(state.goalsHelper);
                    let visit = clone.rows.find(item => (item as GoalsAndVisits)._visit.tims_macvisitid == action.obj.visitId);
                    if (visit != undefined)
                        (visit as GoalsAndVisits)._visit.statuscode = action.obj.statuscode;
                    return { goalsHelper: clone, showLoader: false } as GoalsContState;
                }
            case "POST_VISIT_UPDATE_COMPLETE":
                {
                    let clone = cloneDeep(state.goalsHelper);
                    let visit = clone.rows.find(item => (item as GoalsAndVisits)._visit.tims_macvisitid == action.obj.visitId);
                    if (visit != undefined)
                        (visit as GoalsAndVisits)._visit = action.obj.updatedVisit;
                    return { goalsHelper: clone, showLoader: false } as GoalsContState;
                }

            case "SHOW_VISIT_MODAL":
                {
                    let clone = cloneDeep(state.goalsHelper);
                    let visit = clone.rows.find(item => (item as GoalsAndVisits)._visit.tims_macvisitid == action.obj.visitId) as GoalsAndVisits;
                    setVisitDate(new Date(visit._visit.tims_dateofvisit as string));
                    setVisitType(visit._visit.tims_visittype as number);
                    setOtherVisitNotes(visit._visit.tims_visittypeothertext);
                    setErrorMessageForOther(undefined);
                    setVisitModalMode(VisitModalMode.Edit);
                    setVisitIdForUpdate(action.obj.visitId);

                    showVisitsModal();

                    return { goalsHelper: clone, showLoader: false } as GoalsContState;
                }

        }


        return { goalsHelper: state.goalsHelper, showLoader: false } as GoalsContState;
    }

    const [goalsState, dispatch] = React.useReducer(goalsStateReducer, { goalsHelper: { rows: [] as ComponentHelperBase[] }, showLoader: true } as GoalsContState);

    const canFinaliseVisit = () => {
        let pendingVisit = goalsState.goalsHelper.rows.find(item => (item as GoalsAndVisits).isVisitPending)
        if (pendingVisit?._visit?.goals){
            return (pendingVisit?._visit?.goals.length > 0 || isClearNextVisitDate || excludedVisitTypes.includes(pendingVisit._visit.tims_visittype as number))
        }
        return false
    }

    useEffect(() => {
        if (goalsState.goalsHelper.rows.find(item => (item as GoalsAndVisits).isVisitPending) != undefined) {
            let goalsAndVisits = goalsState.goalsHelper.rows.find(item => (item as GoalsAndVisits).isVisitPending);
            if (goalsAndVisits?._visit?.tims_nextvisitdate != undefined) {
                var nextVisitDate = new Date(goalsAndVisits?._visit?.tims_nextvisitdate);
                setNextVisitDate(nextVisitDate);
            }
            else {
                const today = new Date()
                const tomorrow = new Date(today)
                tomorrow.setDate(tomorrow.getDate() + 1)
                setNextVisitDate(setHours(tomorrow.setHours(0, 0, 0, 0), 9));
            }
        }
    }, [goalsState.goalsHelper.rows.find(item => (item as GoalsAndVisits).isVisitPending) != undefined]);

    const showDateValidationDialog = () => {
        return (
            <Dialog
                hidden={hideDialogDateValidation}
                onDismiss={toggleHideDialogDateValidation}
                dialogContentProps={dialogContentPropsForDateValidation}
                modalProps={modalProps}
            >
                <DialogFooter>
                    <PrimaryButton onClick={toggleHideDialogDateValidation} text="Ok" />
                </DialogFooter>
            </Dialog >);
    }

    React.useEffect(() => {
        const initializeDataLoad = async () => {
            await loadVisitAndGoalsData();
        }
        
        initializeDataLoad();
    }, [showLast, visitTypeFilter, goalStatusFilter]);

    const loadVisitAndGoalsData = async () => {
        let goalsHelper = new GoalsHelper(globalState, showLast, visitTypeFilter, goalStatusFilter);
        await goalsHelper.build();
        dispatch({ type: "INIT", obj: { goalsHelper: goalsHelper, showLoader: false } as GoalsContState });
    }

    const renderDialogAddVisit = () => {
        return (
            <Modal isDarkOverlay={false}
                titleAriaId="Add Visit"
                isOpen={isAddVisitModalOpen }
                onDismiss={hideVisitsModal}
                isBlocking={false}
                containerClassName={contentStyles.container}>
                <div className={contentStyles.header}>
                    {visitModalMode == VisitModalMode.Edit ? <span id="Update_Visit">Update Visit</span> : <span id="Create_Visit">Create Visit</span>}
                    {isVisitCreating ? <div style={{ marginLeft: "10px" }} className="lds-dual-ring"></div> : ""}
                    <IconButton
                        styles={iconButtonStyles}
                        iconProps={cancelIcon}
                        ariaLabel="Close popup modal"
                        onClick={hideVisitsModal}
                    />
                </div>
                <Stack>
                    <Stack tokens={{ childrenGap: 5 }} className={contentStyles.body}>
                        <StackItem>
                            Visit Date:
                            <DatePicker value={visitDate} firstDayOfWeek={DayOfWeek.Sunday} onSelectDate={(date) => {
                                if (date != null && date != undefined)
                                    setVisitDate(date);
                            }} ariaLabel="Select a date" />
                        </StackItem>
                        <StackItem>
                            <Dropdown
                                placeholder="Select Type"
                                label="Type:"
                                options={visitTypeDD}
                                styles={dropdownStyles}
                                selectedKey={visitType}
                                onChange={(e, option, index) => {
                                    if (option?.key != undefined && option.key != null)
                                        setVisitType(option?.key as number)
                                }}
                            />
                        </StackItem>
                        <StackItem>
                            {visitType == 5 ? <TextField label="Other Visit" aria-required="true" errorMessage={errorMessageForOther} value={otherVisitNotes} required onChange={(e, text) => {
                                if (text != undefined)
                                    setOtherVisitNotes(text as string);
                            }} /> : <></>}
                        </StackItem>
                        <StackItem>
                            <DefaultButton style={{ float: "right", marginTop: "7%" }} className="button-style" disabled={isVisitCreating} onClick={async (e) => {
                                let visitModel = new VisitModel(globalState.tokenModel as TokenModel);
                                if (visitType == 5) {
                                    if (otherVisitNotes == undefined) {
                                        setErrorMessageForOther("Field required");
                                        return;
                                    }
                                    else
                                        setErrorMessageForOther(undefined);
                                }
                                showVisitsLoader();
                                if (visitModalMode == VisitModalMode.Create) {
                                    let visit = await goalsState.goalsHelper.createVisit(visitDate, visitType, otherVisitNotes);
                                    if (visit.error != undefined)
                                        Emitter.emit(Events.SetMessageBar, { errors: [visit.error.message], isSuccess: false, requestType: RequestType.CREATE_VISIT, showMessageBar: true } as IMessagebarObject);
                                    else {
                                        let visitWithData = await visitModel.retrieveVisitsById(visit.tims_macvisitid as string, false);
                                        Emitter.emit(Events.SetMessageBar, { errors: [], isSuccess: true, requestType: RequestType.CREATE_VISIT, showMessageBar: true } as IMessagebarObject);
                                        dispatch({ type: "POST_VISIT_CREATE", obj: { visit: visitWithData } });
                                    }
                                }
                                else if (visitModalMode == VisitModalMode.Edit) {
                                    let visit = await goalsState.goalsHelper.updateVisit(visitDate, visitType, otherVisitNotes, visitIdForUpdate as string);
                                    if (visit.ok) {
                                        let updatedVisit = await visitModel.retrieveVisitsById(visitIdForUpdate as string, false);
                                        dispatch({ type: "POST_VISIT_UPDATE_COMPLETE", obj: { visitId: visitIdForUpdate, updatedVisit: updatedVisit } });
                                        Emitter.emit(Events.SetMessageBar, { errors: [], isSuccess: true, requestType: RequestType.UPDATE_VISIT, showMessageBar: true } as IMessagebarObject);
                                    } else {
                                        let error = await visit.json() as Serializable;
                                        Emitter.emit(Events.SetMessageBar, { errors: [error.error?.message], isSuccess: false, requestType: RequestType.UPDATE_VISIT, showMessageBar: true } as IMessagebarObject);
                                    }

                                }


                                hideVisitsLoader();
                                hideVisitsModal();
                            }}>Save</DefaultButton>
                        </StackItem>
                    </Stack>
                </Stack>
            </Modal>
        );
    }

    const finalizeVisit = async () => {
        toggleFinalisingVisit();
        let contactModel = new ContactModel(globalState.tokenModel as TokenModel);
        let appointmentModel = new AppointmentModel(globalState.tokenModel as TokenModel);
        let visit = goalsState.goalsHelper.rows.find(item => (item as GoalsAndVisits).isVisitPending) as GoalsAndVisits;
        let response = await goalsState.goalsHelper.updateVisitStatus(VisitsStatusCode.Active, visit._visit.tims_macvisitid as string);
        //let appointmentScheduledStart = setHours(nextVisitDate.setHours(0, 0, 0, 0), 9);
        let appointmentScheduledEnd = setHours(nextVisitDate, nextVisitDate.getHours() + 1);
        if (response.ok) {

            if (isClearNextVisitDate || isKeepExistingVisitDate) {
                //Skip updating the contact visit dates and creating the new appointment
                Emitter.emit(Events.SetMessageBar, { errors: [], isSuccess: true, requestType: RequestType.UPDATE_VISIT, showMessageBar: true } as IMessagebarObject);
                setIsKeepExistingVisitDate(false);
                if(isClearNextVisitDate){
                    setIsClearNextVisitDate(false)
                    let contactUpdateResponse = await contactModel.updateContactLastVisitDate(null, globalState.contact?.contactid as string, visit._visit.tims_dateofvisit as string);
                    if (!contactUpdateResponse.ok) {
                        let responseObj = {} as Serializable;
                        if (!contactUpdateResponse.ok)
                        responseObj = await contactUpdateResponse.json() as Serializable;
                        var errorArr = [];
                        if (responseObj.error != undefined)
                        errorArr.push(responseObj.error.message);
                        
                        Emitter.emit(Events.SetMessageBar, { errors: errorArr, isSuccess: false, requestType: RequestType.UPDATE_VISIT, showMessageBar: true } as IMessagebarObject);
                    }
                }
                await loadVisitAndGoalsData();
            } else {
                //Update contact visit dates and create appointment for next planned visit
                let contactUpdateResponse = await contactModel.updateContactLastVisitDate(nextVisitDate, globalState.contact?.contactid as string, visit._visit.tims_dateofvisit as string);
                let appointmentDescription = createAppointmentDescription();
                let appointmentResponse = await appointmentModel.createAppointment(globalState.contact?.fullname as string, globalState.contact?.contactid as string, nextVisitDate, appointmentScheduledEnd,
                    globalState.trainingPlanObj?._ownerid_value as string, appointmentDescription) as Serializable;
                if (contactUpdateResponse.ok && appointmentResponse.error == undefined) {
                    Emitter.emit(Events.SetMessageBar, { errors: [], isSuccess: true, requestType: RequestType.UPDATE_VISIT, showMessageBar: true } as IMessagebarObject);
                    await loadVisitAndGoalsData();
                } else {
                    let responseObj = {} as Serializable;
                    if (!contactUpdateResponse.ok)
                        responseObj = await contactUpdateResponse.json() as Serializable;
                    var errorArr = [];
                    if (responseObj.error != undefined)
                        errorArr.push(responseObj.error.message);
                    if (appointmentResponse.error != undefined)
                        errorArr.push(appointmentResponse.error.message);

                    Emitter.emit(Events.SetMessageBar, { errors: errorArr, isSuccess: false, requestType: RequestType.UPDATE_VISIT, showMessageBar: true } as IMessagebarObject);
                }
            }
        }
        else {
            let responseObj = await response.json() as Serializable;
            Emitter.emit(Events.SetMessageBar, { errors: [responseObj.error?.message], isSuccess: false, requestType: RequestType.UPDATE_VISIT, showMessageBar: true } as IMessagebarObject);
        }
        let learner = await contactModel.retrieveContact(globalState.contact?.contactid as string, false) as ContactModel;
        let globalAppContext = { ...globalState, contact: learner } as GlobalStateModel;
        globalState.setGlobalState(globalAppContext);
        toggleFinalisingVisit();
    }

    const createAppointmentDescription = () => {

        var appointmentBody = "";
        appointmentBody += "" + globalState.contact?.fullname + "<br>";
        appointmentBody += `<a href="${getUrlForCRM("contact", globalState.contact?.contactid as string)}">${getUrlForCRM("contact", globalState.contact?.contactid as string)}</a><br><br>`;

        appointmentBody += globalState.trainingPlanObj?.tims_name + "<br>";
        appointmentBody += `<a href="${getUrlForCRM("tims_trainingplan", globalState.trainingPlanObj?.tims_trainingplanid as string)}">${getUrlForCRM("tims_trainingplan", globalState.trainingPlanObj?.tims_trainingplanid as string)}</a><br><br>`;

        appointmentBody += Common.getFormattedValue(globalState.trainingContractObj, "_tims_accountworkplaceid_value") + "<br>";
        appointmentBody += `<a href="${getUrlForCRM("account", globalState.trainingContractObj?._tims_accountworkplaceid_value as string)}">${getUrlForCRM("account", globalState.trainingContractObj?._tims_accountworkplaceid_value as string)}</a><br><br>`;

        return appointmentBody;

    }

    const getUrlForCRM = (entityName: string, entityId: string) => {
        return globalState.tokenModel?.apiUrl + `/main.aspx?etn=${entityName}&id=${entityId}&pagetype=entityrecord&forceUCI=1`;
    }

    //
    const renderFinalizeVisitConfirmationDialog = () => {
        return (
            <Dialog
                hidden={hideDialog}
                onDismiss={toggleHideDialog}
                dialogContentProps={dialogContentProps}
                modalProps={modalProps}
            >
                <DialogFooter>
                    {isFinalisingVisit ? <div style={{ marginLeft: "10px", paddingTop: "13px" }} className="lds-dual-ring"></div> : ""}
                    <PrimaryButton disabled={isFinalisingVisit} onClick={async (e) => {
                        await finalizeVisit();
                        toggleHideDialog();
                    }} text="Yes" />
                    <DefaultButton disabled={isFinalisingVisit} onClick={toggleHideDialog} text="No" />
                </DialogFooter>
            </Dialog>
        );
    }

    return (<div style={{ marginLeft: "12px", marginTop: "30px", paddingRight: "10px", marginBottom:"30px" }}>
        <div style={{ marginBottom: "12px" }}><b style={{ fontSize: "19px" }}>Visits and Goals</b></div>
        {goalsState.showLoader ?
            <Stack style={{ display: "block", marginLeft: "auto", marginRight: "auto", textAlign: "center" }}>
                <Loader
                    type="TailSpin"
                    color="#00BFFF"
                    height={100}
                    width={100}
                    timeout={30000} //3 secs
                />
            </Stack> :
            <goalsControlContext.Provider value={dispatch}>
                <Stack className="editPanel" tokens={{ childrenGap: 10 }} style={{ marginBottom: "20px" }} horizontal>
                    <StackItem>
                        <Dropdown
                            placeholder="Goal Status"
                            label="Goal status:"
                            multiSelect
                            options={goalStatusDD}
                            styles={dropdownStyles}
                            selectedKeys={goalStatusFilter}
                            onChange={async (e, option, index) => {

                                if (option) {
                                    setGoalStatusFilter(
                                        option.selected ? [...goalStatusFilter, option.key as number] : goalStatusFilter.filter(key => key !== option.key),
                                    );
                                    dispatch({ type: "SHOW_LOADER" });
                                }
                            }}
                            >

                        </Dropdown>
                    </StackItem>
                    <StackItem>
                        <Dropdown
                            placeholder="Select Last visit"
                            label="Show last:"
                            options={showLastDD}
                            styles={dropdownStyles}
                            selectedKey={showLast}
                            onChange={async (e, option, index) => {
                                if (option?.key != undefined && option.key != null) {
                                    setShowLast(option?.key as number);
                                    dispatch({ type: "SHOW_LOADER" });
                                }


                            }} />
                    </StackItem>
                    <StackItem>
                        <Dropdown
                            placeholder="Select Type"
                            label="Type:"
                            multiSelect
                            options={visitTypeDD}
                            styles={dropdownStyles}
                            selectedKeys={visitTypeFilter}
                            onChange={async (e, option, index) => {

                                if (option) {
                                    setVisitTypeFilter(
                                        option.selected ? [...visitTypeFilter, option.key as number] : visitTypeFilter.filter(key => key !== option.key),
                                    );
                                    dispatch({ type: "SHOW_LOADER" });
                                }
                            }} />

                    </StackItem>
                </Stack>
                {goalsState.goalsHelper.rows.length == 0 ? <div style={{ textAlign: "center" }}>
                    No visits found
                </div> : <>
                    <div>
                        <VisitGridControl goalsHelper = {goalsState.goalsHelper}></VisitGridControl>
                    </div>
                </>}
                {goalsState.goalsHelper.rows.every((item, index) => { return !(item as GoalsAndVisits).isVisitPending }) ?
                    <>
                        {!globalState.IsPortalMode ?
                            <div style={{ float: "left" }}>
                                <DefaultButton className="button-style grid-button" onClick={async (e) => {
                                    setVisitDate(new Date());
                                    setVisitType(1);
                                    setOtherVisitNotes(undefined);
                                    setErrorMessageForOther(undefined);
                                    setVisitModalMode(VisitModalMode.Create);
                                    showVisitsModal();

                                }}>Add Visit</DefaultButton>
                            </div> : <></>}
                    </> : <>
                        {!globalState.IsPortalMode && goalsState.goalsHelper.rows.find(item => (item as GoalsAndVisits).isVisitPending) != undefined ?
                            <>

                                <div style={{ width: "80%", float: "left" }}>
                                    <Stack tokens={{ childrenGap: 40 }} horizontal>
                                        <StackItem>Date of next visit:</StackItem>
                                            <StackItem style={{ width: "20%" }}>
                                                <DateTimePicker popup={DateOfNextVisitPopUp} disabled={isClearNextVisitDate || isKeepExistingVisitDate } format={"dd/MM/yyyy hh:mm a"} value={nextVisitDate} defaultValue={nextVisitDate} min={today} onChange={(event) => {
                                                    if (event.value != null && event.value != undefined)
                                                    setNextVisitDate(event.value);
                                                }} />
                                            </StackItem>
                                            {!globalState.IsPortalMode ?
                                                <Checkbox label="Clear next visit date" disabled={globalState.IsPortalMode || isKeepExistingVisitDate} checked={isClearNextVisitDate} onChange={onClearNextVisitDateChange} />
                                            : <></>}
                                            {!globalState.IsPortalMode ?
                                                <Checkbox label="Keep existing visit date" disabled={globalState.IsPortalMode || isClearNextVisitDate} checked={isKeepExistingVisitDate} onChange={onKeepExistingVisitDateChange} />
                                                : <></>}
                                    </Stack>
                                </div>
                                <div style={{ float: "right" }}>
                                    <TooltipHost 
                                        content = {canFinaliseVisit() ? "" : "Visit cannot be finalised until at least one goal is added" } 
                                        id={tooltipId} 
                                        calloutProps={tooltipCalloutProps} 
                                        styles={tooltipHostStyles}
                                        >
                                    <DefaultButton aria-describedby={tooltipId} disabled={!canFinaliseVisit()} className="button-style grid-button" onClick={async (e) => {

                                        let visit = goalsState.goalsHelper.rows.find(item => (item as GoalsAndVisits).isVisitPending) as GoalsAndVisits;
                                        let visitDate = new Date(visit._visit.tims_dateofvisit as string);
                                        let todaysDate = new Date();
                                        if (visitDate > todaysDate) {
                                            toggleHideDialogDateValidation();
                                            return;
                                        }

                                        toggleHideDialog();
                                    }}>Finalise Visit</DefaultButton>
                                    </TooltipHost>
                                    {renderFinalizeVisitConfirmationDialog()}
                                    {showDateValidationDialog()}
                                </div>
                            </>
                            : <></>}</>}
            </goalsControlContext.Provider >}
        {renderDialogAddVisit()}
    </div>
    );
}